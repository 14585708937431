import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { IWorkListModel, WorkListSearchDropdowns, IWorkList, IBasicFilter, IMainFilter, IContactModel } from '../models/Iworklist-model';
import { environment } from '../../environments/environment';
import { AdvancedSearchDropdowns } from '../models/Iadvanced-search-model';
import { Subject } from 'rxjs';
import { Constants } from '../Constants/WorkListConstants';

@Injectable({
  providedIn: 'root',
  deps: [HttpClient],
})
export class WorklistService {
  private contactDropdownSubject = new Subject<any>();
  private tableViewSelectedSubject = new Subject<string>();

  constructor(private http: HttpClient, private config: ConfigurationService) { }

  private readonly httpOptions = {
    headers: new HttpHeaders({ Accept: 'application/json' }),
    withCredentials: true,
    observe: 'response' as 'response',
  };

  sendTableViewSelectedData(value){
    this.tableViewSelectedSubject.next(value != null ? value :"");
  }

  getTableViewSelectedSubjectAsObservable(): Observable<string>{
    return this.tableViewSelectedSubject.asObservable();
  }

  sendActivityData(idArray) {
    this.contactDropdownSubject.next(idArray != null ? idArray : null);
  }

  getContactSubjectAsObservable(): Observable<any> {
    return this.contactDropdownSubject.asObservable();
  }

  getWorkListData(filterCriteria: IMainFilter): Observable<IWorkList> {
    let searchType = filterCriteria?.globalFilter?.searchType.toString() == Constants.My_Locked_Cases ? Constants.Search_Result : filterCriteria?.globalFilter?.searchType.toString();
    const params = new HttpParams()
      .set('assessmentTypeIds', filterCriteria?.basicFilter?.assessmentTypeIds.toString())
      .set('caseManager', filterCriteria?.basicFilter?.caseManager.toString())
      .set('hospitalId', filterCriteria?.basicFilter?.hospitalId.toString())
      .set('patientFirstName', filterCriteria?.basicFilter?.patientFirstName.toString())
      .set('patientLastName', filterCriteria?.basicFilter?.patientLastName.toString())
      .set('attendingPhysicianId', filterCriteria?.basicFilter?.attendingPhysicianId.toString())
      .set('payerName', filterCriteria?.basicFilter?.payerName.toString())
      .set('searchType', searchType)
      .set('skipCount', filterCriteria.skipCount.toString())
      .set('maxCount', filterCriteria.maxCount.toString())
      .set('inUse', filterCriteria?.basicFilter?.inUse.toString())
      .set('recentReviewTimeSpan', filterCriteria?.basicFilter?.recentReviewTimeSpan.toString())
      .set('accountNumber', filterCriteria?.basicFilter?.accountNumber.toString())
      .set('isActiveClients', filterCriteria?.basicFilter?.isActiveClients.toString())
      .set('isIncludeClosed', filterCriteria?.basicFilter?.isIncludeClosed.toString())
      .set('isIncludeDeleted', filterCriteria?.basicFilter?.isIncludeDeleted.toString())
      .set('searchField', filterCriteria?.globalFilter?.searchField?.toString())
      .set('memberId', filterCriteria?.advancedFilter?.memberId.toString())
      .set('initialOrderId', filterCriteria?.advancedFilter?.initialOrderId.toString())
      .set('ocgId', filterCriteria?.advancedFilter?.ocgId.toString())
      .set('recommendationId', filterCriteria?.advancedFilter?.recommendationId.toString())
      .set('intakeSourceId', filterCriteria?.advancedFilter?.intakeSourceId.toString())
      .set('fromAdmitDate', filterCriteria?.basicFilter.fromAdmitDate?.toString())
      .set('toAdmitDate', filterCriteria?.basicFilter.toAdmitDate?.toString())
      .set('fromDischargeDate', filterCriteria?.basicFilter.fromDischargeDate?.toString())
      .set('toDischargeDate', filterCriteria?.basicFilter.toDischargeDate?.toString())
      .set('createdDateRangeFromDate', filterCriteria?.globalFilter?.createdDateRange?.fromDate?.toString())
      .set('createdDateRangeToDate', filterCriteria?.globalFilter?.createdDateRange?.toDate?.toString())
      .set('sorting', filterCriteria.sorting.toString())
      .set('healthSystemId', filterCriteria?.basicFilter?.healthSystem.toString())
      .set('activityIds', filterCriteria?.basicFilter?.activities.toString())
      .set('statusNames', filterCriteria?.basicFilter?.status.toString())
      .set('assignedTo',filterCriteria?.basicFilter?.assignedTo.toString())
      .set('isGlobalRestriction', filterCriteria?.basicFilter?.isGlobalRestriction.toString())
      .set('isGlobalRestrictionPR', filterCriteria?.basicFilter?.isGlobalRestrictionPR.toString())
      .set('isOpenActivityOnly', filterCriteria?.advancedFilter?.isOpenActivityOnly.toString())
      .set('TaskStatus',filterCriteria?.advancedFilter?.taskStatus?.toString())
      .set('ContactMethod',filterCriteria?.advancedFilter?.contactMethod?.toString())
      .set('ContactReason',filterCriteria?.advancedFilter?.contactReason?.toString())
      .set('ContactStatus',filterCriteria?.advancedFilter?.contactStatus?.toString())
      .set('ContactOutcome',filterCriteria?.advancedFilter?.contactOutcome?.toString())
      .set('ContactResponse',filterCriteria?.advancedFilter?.contactResponse?.toString())
      .set('FLRCriteriaStatus', filterCriteria.advancedFilter?.flrCriteriaStatus.toString())
      .set('isDischargeSummaryOnly', filterCriteria?.advancedFilter?.isDischargeSummaryOnly.toString())
      .set('isPayerRefusalOnly', filterCriteria?.advancedFilter?.isPayerRefusalOnly.toString())
      ;

    const httpOptions = {
      headers: this.httpOptions.headers,
      withCredentials: true,
      observe: 'response' as 'response',
      params,
    };
    return this.http.get<IWorkList>(environment.apis.default.url + '/api/OpasLandingPageApi/GetList', httpOptions).pipe(
      map((res) => {
        return { ...res.body, data: res.body && res.body.data ? res.body.data : [], };
      }),
      catchError((error) => throwError(error))
    );
  }
  
  getConfigSetting(configKey:string): Observable<string>{

    const httpOptions = {
      headers: this.httpOptions.headers,
      withCredentials: true,
      Observe: 'response' as 'response',
    }
    
    return this.http.get<string>(environment.apis.default.url + '/api/OpasLandingPageApi/GetConfigSetting?configKey='+configKey, httpOptions).pipe(
      map((data) => data),
      catchError((error) => throwError(error))
    );
  }

  getWorkListSearchData(): Observable<WorkListSearchDropdowns> {
    const httpOptions = {
      headers: this.httpOptions.headers,
      withCredentials: true,
      Observe: 'response' as 'response'
    }
    return this.http.get<WorkListSearchDropdowns>(environment.apis.default.url + '/api/OpasLandingPageApi/GetSearchDropdownData', httpOptions).pipe(
      map((data) => data),
      catchError((error) => throwError(error))
    );
  }

  getAllUserRoles(): Observable<any> {
    const httpOptions = {
      headers: this.httpOptions.headers,
      withCredentials: true,
      Observe: 'response' as 'response'
    }

    return this.http.get<any>(environment.apis.default.url + '/api/AuthorizationApi/GetUserRoles', httpOptions).pipe(
      map((data) => data),
      catchError((error) => throwError(error))
    );
  }

  getAssignableUsers(): Observable<any> {
    const httpOptions = {
      headers: this.httpOptions.headers,
      withCredentials: true,
      Observe: 'response' as 'response'

    }
    return this.http.get<any>(environment.apis.default.url + '/api/OpasLandingPageApi/GetAssignableUsers', httpOptions).pipe(
      map((data) => data),
      catchError((error) => throwError(error))
    );
  }

  unlockAssessment(assessmentId: number, assignToUser: string): Observable<any> {
    const httpOptions = {
      headers: this.httpOptions.headers,
      withCredentials: true,
      Observe: 'response' as 'response'

    }
    const assessmentInputModel = { AssessmentId: assessmentId, AssignToUser: assignToUser };
    return this.http.post<any>(environment.apis.default.url + '/api/OpasLandingPageApi/UnlockAssessment', assessmentInputModel, httpOptions).pipe(
      map((data) => data),
      catchError((error) => throwError(error))
    );
  }

  GetMyNextCase(dispenseAssessmentModel : any): Observable<any> {
    const httpOptions = {
      headers: this.httpOptions.headers,
      withCredentials: true,
      Observe: 'response' as 'response'
    }

    return this.http.post<any>(environment.apis.default.url + '/api/OpasLandingPageApi/DispenseAssessments', dispenseAssessmentModel, httpOptions).pipe(
      map((data) => data),
      catchError((error) => throwError(error))
    );
  }

  GetCaseInUseAssessmentIds(): Observable<any> {
    const httpOptions = {
      headers: this.httpOptions.headers,
      withCredentials: true,
      Observe: 'response' as 'response'

    }
    return this.http.get<any>(environment.apis.default.url + '/api/OpasLandingPageApi/GetCaseInUseAssessmentIds', httpOptions).pipe(
      map((data) => data),
      catchError((error) => throwError(error))
    );
  }

  getAdvancedSearchDropDownData(): Observable<AdvancedSearchDropdowns> {
    const httpOptions = {
      headers: this.httpOptions.headers,
      withCredentials: true,
      Observe: 'response' as 'response'
    }
    return this.http.get<AdvancedSearchDropdowns>(environment.apis.default.url + '/api/OpasLandingPageApi/GetAdvancedSearchDropdownsData', httpOptions).pipe(
      map((data) => data),
      catchError((error) => throwError(error))
    );
  }

  getUserPermissionForOSPAToggle(): Observable<any> {

    const httpOptions = {
      headers: this.httpOptions.headers,
      withCredentials: true,
      Observe: 'response' as 'response'
    }
    return this.http.get<any>(environment.apis.default.url + '/api/UserApi/IsUserOSPA', httpOptions).pipe(
      map((data) => data),
      catchError((error) => throwError(error))
    );
  }

  getUserIdentityRolesPermissions(): Observable<any> {
    const httpOptions = {
      headers: this.httpOptions.headers,
      withCredentials: true,
      Observe: 'response' as 'response'
    }

    return this.http.get<any>(environment.apis.default.url + '/api/UserApi/GetRolesPermissions', httpOptions).pipe(
      map((data) => data),
      catchError((error) => throwError(error))
    );
  }

  IsCurrentUserAcceptingCases(): Observable<any> {
    const httpOptions = {
      headers: this.httpOptions.headers,
      withCredentials: true,
      Observe: 'response' as 'response'

    }
    return this.http.get<boolean>(environment.apis.default.url + '/api/UserApi/IsCurrentUserAcceptingCases', httpOptions).pipe(
      map((data) => data),
      catchError((error) => throwError(error))
    );
  }

  UpdateAcceptingCases(isUserAcceptingCases: boolean): Observable<any> {
    const httpOptions = {
      headers: this.httpOptions.headers,
      withCredentials: true,
      Observe: 'response' as 'response'

    }
    const userAcceptingCasesModel = { IsUserAcceptingCases: isUserAcceptingCases };
    return this.http.post<boolean>(environment.apis.default.url + '/api/UserApi/UpdateAcceptingCases', userAcceptingCasesModel, httpOptions).pipe(
      map((data) => data),
      catchError((error) => throwError(error))
    );
  }

  getContacts(
    hospitalId: number,
    contactTypeId?: number
  ): Observable<Array<IContactModel>> {
    const params = new HttpParams()
      .append('hospitalId', hospitalId.toString())
      .append(
        'contactTypeId',
        contactTypeId && contactTypeId > 0 ? contactTypeId.toString() : '0'
      );
    const httpOptions = {
      headers: this.httpOptions.headers,
      withCredentials: true,
      params,
    };

    let result = null;
    result = this.http.get<Array<IContactModel>>(
      environment.apis.default.url + '/api/ContactAPI/GetContactList',
      httpOptions
    );
    return result;
  }
  LockCase(assessmentId: number): Observable<any> {
    const httpOptions = {
      headers: this.httpOptions.headers,
      withCredentials: true,
      Observe: 'response' as 'response'

    }   
    return this.http.post<any>(environment.apis.default.url + '/api/CaseInUseApi/LockCase?assessmentid='+assessmentId,null, httpOptions).pipe(
      map((data) => data),
      catchError((error) => throwError(error))
    );
  }
}

