import { ChangeDetectorRef, Component, OnInit, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import {
  UITKTableDataSource
} from '@uitk/angular';
import { Constants } from '../Constants/WorkListConstants';
import { IBasicFilter, IDateRange, IGlobalFilter, IWorkListModel, SelectionModel, WorkListSearch, WorkListSearchDropdowns } from '../models/Iworklist-model';
import { WorklistService } from '../services/worklist.service';
import { UITKInputModule,UITKIconModule } from '@uitk/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatInputModule, MatInput } from '@angular/material/input';

@Component({
  selector: 'global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {

  public tableViewTypes: SelectionModel[] = [{ value: 1, label: Constants.My_Reviews },
    { value: 3, label: Constants.My_Recent_Reviews },
  { value: 2, label: Constants.Search_Result }]
  //r public createdDateRangeSelectedModel?: SelectionModel;
  public tableViewSelectedModel?: SelectionModel;
  public globalFilter?: IGlobalFilter = {
    searchType: '',
    createdDateRange: { fromDate: '', toDate: '' }, searchField: ''
  };
  public searchField: string = '';
  public isSearchPanelOpen: boolean = true;
  public createdDateRangeModel?: IDateRange = { fromDate: '', toDate: '' };

  public isCreatedDateStartErrorState: boolean = false;
  public isCreatedDateEndErrorState: boolean = false;
  public isCreatedDateRangeInvalid: boolean = false;

  @Input() openPanel: boolean;
  @Input() isEnableF245991: boolean;
  @Output() globalSearchPanelClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private readonly workListService: WorklistService) {
  }

  ngOnInit(): void {
    this.setInitialFilter();
    this.updateTableViewTypes();
  }

  public setInitialFilter() {
    const sel: SelectionModel = { value: 1, label: Constants.My_Reviews };
    this.tableViewSelectedModel = sel;
    this.globalFilter.searchType = sel.label;
    this.workListService.sendTableViewSelectedData(this.globalFilter.searchType);
  }


  onTableViewDropdownChange(selectedItem) {
    this.tableViewSelectedModel = selectedItem?.value;
    this.globalFilter.searchType = this.tableViewSelectedModel?.label;
    this.workListService.sendTableViewSelectedData(this.globalFilter.searchType);
  }

  public onGlobalSearchPanelClick(event: any) {
    //when space or enter key hits on panel header, panel will collapse or expand, so to overcome this behaviour need below code.
    this.loadPersistedSearchPanel();
    this.globalSearchPanelClicked.emit(this.isSearchPanelOpen);
  }

  public loadPersistedSearchPanel() {
    const searchPanelStateJson = window.localStorage.getItem('searchPanelState');
    if (searchPanelStateJson !== undefined && searchPanelStateJson != null) {
      this.isSearchPanelOpen = !JSON.parse(searchPanelStateJson) as boolean
    }
    else {
      this.isSearchPanelOpen = !true;
    }
  }

  public filterValues(): IGlobalFilter {
    //r this.globalFilter.createdDateRange = this.createdDateRangeSelectedModel?.value?.toString() ?? "0";
    this.globalFilter.searchType = this.tableViewSelectedModel?.label;
    this.globalFilter.searchField = this.searchField;
    this.globalFilter.createdDateRange.fromDate = this.formatDate(this.createdDateRangeModel.fromDate);
    this.globalFilter.createdDateRange.toDate = this.formatDate(this.createdDateRangeModel.toDate);
    return this.globalFilter;
  }

  public ClearGlobalSearch() {
    this.searchField = '';
    this.createdDateRangeModel.fromDate = '';
    this.createdDateRangeModel.toDate = '';
    this.ClearGlobalSearchFilters();
    this.setInitialFilter();
    this.isCreatedDateRangeInvalid = false;
    this.isCreatedDateStartErrorState = false;
    this.isCreatedDateEndErrorState = false;
  }

  private ClearGlobalSearchFilters() {
    //r this.globalFilter.createdDateRange = '';
    this.globalFilter.searchField = '';
    this.globalFilter.searchType = '';
    this.globalFilter.createdDateRange.fromDate = '';
    this.globalFilter.createdDateRange.toDate = '';
  }

  onAcctKeydown($event) {
    let invalidChars = ["-", "+", "e", "E"];
    if (invalidChars.includes($event.key)) {
      $event.preventDefault();
    }

    //when space or enter key hits on panel header, panel will collapse or expand, so to overcome this behaviour need below code.
    if ($event.keyCode === 32 || $event.keyCode === 13) {
      this.loadPersistedSearchPanel();
      this.globalSearchPanelClicked.emit(this.isSearchPanelOpen);
      if ($event.keyCode === 32) {
        $event.target.value = $event.target.value + " ";
      }
      else if ($event.keyCode === 13) {
        return;
      }
    }
  }

  private formatDate(dateReceived: string): string {
    if (
      dateReceived == null ||
      new Date(dateReceived).toString() === 'Invalid Date'
    ) {
      return '';
    } else {
      const date = new Date(dateReceived);
      return ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear();
    }
  }

  public validateCreatedDateRange(): boolean {
    if (this.isCreatedDateStartErrorState === false && this.isCreatedDateEndErrorState === false && (this.createdDateRangeModel?.fromDate === null || this.createdDateRangeModel?.fromDate?.length === 0 ) &&
      (this.createdDateRangeModel?.toDate === null || this.createdDateRangeModel?.toDate?.length === 0)) {
      this.isCreatedDateRangeInvalid = false;
    }
    else if (((this.isCreatedDateStartErrorState == false && this.createdDateRangeModel?.fromDate !== null ) && (this.createdDateRangeModel?.toDate === null || this.createdDateRangeModel?.toDate?.length === 0)) ||
      ((this.isCreatedDateEndErrorState === false && this.createdDateRangeModel?.toDate !== null )&& (this.createdDateRangeModel?.fromDate === null || this.createdDateRangeModel?.fromDate?.length === 0))) {
      this.isCreatedDateRangeInvalid = true;
    }
    else if (this.isCreatedDateStartErrorState || this.isCreatedDateEndErrorState) {
      this.isCreatedDateRangeInvalid = true;
    }
    else {
      this.isCreatedDateRangeInvalid = false;
    }
    return this.isCreatedDateRangeInvalid;
  }

  onCreatedDateStartChange($event) {
    this.isCreatedDateRangeInvalid = false;
    this.isCreatedDateStartErrorState = false;
    if ($event?.target?.ngControl?.control?.status !== "VALID") {  
      this.isCreatedDateRangeInvalid = true;
      this.isCreatedDateStartErrorState = true;
    }
    this.validateCreatedDateRange();
  }

  onCreatedDateEndChange($event) {
    this.isCreatedDateRangeInvalid = false;
    this.isCreatedDateEndErrorState = false;
    if ($event?.target?.ngControl?.control?.status !== "VALID") {     
      this.isCreatedDateRangeInvalid = true;
      this.isCreatedDateEndErrorState = true;
    }
    this.validateCreatedDateRange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isEnableF245991'] && !changes['isEnableF245991'].isFirstChange()) {
      this.updateTableViewTypes();
    }
  }
  public updateTableViewTypes(): void {
    if (this.isEnableF245991) {
      this.tableViewTypes = [{ value: 1, label: Constants.My_Reviews },
      { value: 3, label: Constants.My_Recent_Reviews },
      { value: 4, label: Constants.My_Locked_Cases },
      { value: 2, label: Constants.Search_Result }];
    }
  }
}
